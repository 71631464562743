import React from 'react'
import {useRef} from 'react'

import './contact.css'

import emailjs from 'emailjs-com'

import {AiOutlineMail} from 'react-icons/ai'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('EmailJS-Website', 'template_w3kp3rr', form.current, 'mZDB_wj0CifMLDEkw');

    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>nick@wahrenberger.net</h5>
            <a href="emailto:nick@wahrenberger.net" target="_blank">Send a Email</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required/>
            <input type="email" name='email' placeholder='Your Email' required/>
            <textarea name='message' rows="7" placeholder='Your Message' required/>
            <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact