import React from 'react'
import './about.css'

import {MdOutlineWork} from 'react-icons/md'
import {TiFlashOutline} from 'react-icons/ti'
import {BsStopwatchFill} from 'react-icons/bs'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <MdOutlineWork className='about__icon'/>
              <h5>Software Engineer</h5>
              <small>3+ Years Working</small>
            </article>
            <article className='about__card'>
              <TiFlashOutline className='about__icon'/>
              <h5>Electrical Engineer</h5>
              <small>3+ Years Working</small>
            </article>
            <article className='about__card'>
              <BsStopwatchFill className='about__icon'/>
              <h5>Ambitious &amp; Efficient</h5>
              <small>Slight structural neurosis ;)</small>
            </article>
          </div>

          <p>
          Due to my technical background as an electrical engineer, I quickly switched to information technology and thus software development.
          This is where I discovered my passion in the working world and I am currently working as a software developer in the backend at CAMAO TEC. 
          As a coding enthusiast, I spend almost every free minute dealing with software development topics. 
          Whether in the form of content from my extra-occupational studies, hobby IoT projects, small applications, various books or especially podcasts.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About