import React from 'react'
import './experience.css'

import {SiReact} from 'react-icons/si'
import {DiJavascript} from 'react-icons/di'
import {AiFillHtml5} from 'react-icons/ai'
import {SiCss3} from 'react-icons/si'

import {TbCSharp} from 'react-icons/tb'
import {TbBrandPython} from 'react-icons/tb'
import {CgCPlusPlus} from 'react-icons/cg'
import {VscCode} from 'react-icons/vsc'
import {SiDotnet} from 'react-icons/si'
import {GoDatabase} from 'react-icons/go'
import {FaDocker} from 'react-icons/fa'
import {SiKubernetes} from 'react-icons/si'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <SiReact className='experience__details-icon-react'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiCss3 className='experience__details-icon-css'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className='experience__details'>
              <AiFillHtml5 className='experience__details-icon-html'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>
            <article className='experience__details'>
              <DiJavascript className='experience__details-icon-js'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
        <h3>Backend Development (professional)</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <TbCSharp className='experience__details-icon-csharp'/>
              <div>
                <h4>C-Sharp</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiDotnet className='experience__details-icon-dotnet'/>
              <div>
                <h4>.Net</h4>
                <small className='text-light'>Advanced</small>
              </div>
            </article>
            <article className='experience__details'>
              <TbBrandPython className='experience__details-icon-python'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <GoDatabase/>
              <div>
                <h4>SQL &amp; No-SQL</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <CgCPlusPlus/>
              <div>
                <h4>C++</h4>
                <small className='text-light'>Regular</small>
              </div>
            </article>
            <article className='experience__details'>
              <VscCode/>
              <div>
                <h4>Go</h4>
                <small className='text-light'>Experimental</small>
              </div>
            </article>
            <article className='experience__details'>
              <FaDocker className='experience__details-icon-docker'/>
              <div>
                <h4>Docker</h4>
                <small className='text-light'>Experimental</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiKubernetes className='experience__details-icon-kubernetes'/>
              <div>
                <h4>K8</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default Experience