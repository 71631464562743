import React from 'react'

import {FaXingSquare} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.xing.com/profile/Nick_Wahrenberger/cv" target="_blank"><FaXingSquare/></a>
        <a href="https://github.com/SoftTechNick" target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials