import React from 'react'
import {useState} from 'react'

import './nav.css'
import { AiOutlineHome } from 'react-icons/ai'
import { FaRegUser } from 'react-icons/fa'
import { HiCode } from 'react-icons/hi'
import { RiMessage2Line } from 'react-icons/ri'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      <a href="#"
        onClick={() => setActiveNav('#')} 
        className={activeNav === '#' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a href="#about" 
        onClick={() => setActiveNav('#about')} 
        className={activeNav === '#about' ? 'active' : ''}>
        <FaRegUser />
      </a>
      <a href="#experience" 
        onClick={() => setActiveNav('#experience')} 
        className={activeNav === '#experience' ? 'active' : ''}>
        <HiCode />
      </a>
      <a href="#contact" 
        onClick={() => setActiveNav('#contact')} 
        className={activeNav === '#contact' ? 'active' : ''}>
        <RiMessage2Line />
      </a>
    </nav>
  )
}

export default Nav